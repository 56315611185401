import { RefServiceType } from "./Ref.dto";
import { LoLang } from "../common/common.types";

export function ReadableRefServiceType(serviceType: RefServiceType, lang?: LoLang | undefined, defaultVal = "-"): string {
  switch (serviceType) {
    case RefServiceType.Suite:
      return "Maven Clinical Cloud";
    case RefServiceType.Docs:
      return "Maven Docs";
    case RefServiceType.Safety:
      return "Maven Safety";
    case RefServiceType.Builder:
      return "Maven Builder";
    case RefServiceType.Resource:
      return "Maven Resource";
    case RefServiceType.SSO:
      return "Maven SSO";
    case RefServiceType.Auth:
      return "Maven Auth";
    case RefServiceType.Portal:
      return "Maven Portal";
    case RefServiceType.Project:
      return "Maven Project";
    case RefServiceType.Billing:
      return "Maven Billing";
    case RefServiceType.Validator:
      return "Maven Validator";
    case RefServiceType.Paths:
      return "Paths";
    default:
      return serviceType || defaultVal;
  }
}
