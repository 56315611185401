import isEmail from "validator/lib/isEmail";
import { AuthUserRefAuditDataPayload, AuthUserRefAuditDataReserved } from "@maven-rest/auth/RefAudit.dto";
import { isEqual } from "lodash";

export function getUserRefAuditDataReserved(
  beforePayload: AuthUserRefAuditDataPayload | null,
  afterPayload: AuthUserRefAuditDataPayload
): Array<AuthUserRefAuditDataReserved> {
  const updatedPayload: AuthUserRefAuditDataPayload = beforePayload
    ? Object.keys(afterPayload).reduce((prev, key: keyof AuthUserRefAuditDataPayload) => {
        if (!isEqual(afterPayload[key], beforePayload[key])) {
          prev = { ...prev, [key]: afterPayload[key] };
        }

        return prev;
      }, {})
    : afterPayload;

  return Object.keys(updatedPayload).reduce((prev, key: keyof AuthUserRefAuditDataPayload) => {
    switch (key) {
      case "name": {
        prev.push(AuthUserRefAuditDataReserved.Name);
        break;
      }
      case "email": {
        prev.push(AuthUserRefAuditDataReserved.Email);
        break;
      }
      case "phone": {
        prev.push(AuthUserRefAuditDataReserved.Phone);
        break;
      }
      case "agreeMarketing": {
        prev.push(AuthUserRefAuditDataReserved.AgreeMarketing);
        break;
      }
      case "passwordUpdatedAt": {
        if (beforePayload !== null) {
          prev.push(AuthUserRefAuditDataReserved.PasswordUpdatedAt);
        }
        break;
      }
      default:
        throw new Error(`Not supported user audit data payload property (property: ${key})`);
    }

    return prev;
  }, []);
}

export function isPhoneNumberValid(phoneNumber: string): boolean {
  return /^[0-9]{8,11}$/.test(phoneNumber);
}

export const isValidDomainEmail = (email: string, domain = "jnpmedi.com"): boolean => isEmail(email) && domain === email.split("@").pop();

export function isUserPasswordValid(password: string, id?: string): boolean {
  // NOTE: 영문, 숫자, 특수문자(키보드에서 사용할 수 있는 모든 특수문자) 포함 8자리 이상, id 있는 경우 password, id 값 불일치
  if (
    password.match(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\\!@#$%^&*()_+,./?<>`~{}[\]=;:"'|-])[\\A-Za-z\d!@#$%^&*()_+,./?<>`~{}[\]=;:"'|-]{8,128}$/
    ) &&
    (!id || password !== id)
  ) {
    return true;
  } else {
    return false;
  }
}
