import React, { useState } from "react";
import { CrDialog } from "@maven-surface/components/base/CrDialog";
import { CrInput } from "@maven-surface/components/base/CrInput";
import { CrInputGroup } from "@maven-surface/components/base/CrInputGroup";
import { AUTH_USER_VERIFICATION_CODE_LANG, COMMON_LANG } from "@maven-msg/lang";
import { MvnMessage } from "@maven-msg/lib/Message";
import { UserVerificationCodeFlax } from "@maven-rest/sso/UserVerificationCode.flax";

interface InitPasswordDialogProps {
  visibility: boolean;
  email: string;
  verificationCodeUuid: string;
  onVerified: (code: string) => void;
  onCancel: () => void;
}

export function SignUpEmailVerificationDialog(props: InitPasswordDialogProps) {
  const { visibility, email, verificationCodeUuid, onVerified, onCancel } = props;
  const [code, setCode] = useState("");

  return (
    <CrDialog
      visibility={visibility}
      size="WIDE"
      header={MvnMessage(AUTH_USER_VERIFICATION_CODE_LANG.USER_VERIFICATION_CODE_EMAIL_DIALOG_HEADER)}
      title={MvnMessage(AUTH_USER_VERIFICATION_CODE_LANG.USER_VERIFICATION_CODE_EMAIL_DIALOG_TITLE, { email })}
      onConfirm={{
        api: () => UserVerificationCodeFlax.verifyUserVerificationCode({ uuid: verificationCodeUuid, code }),
        delegate: {
          success: () => {
            onVerified(code);
          },
          error: (error) => MvnMessage(AUTH_USER_VERIFICATION_CODE_LANG.USER_VERIFICATION_CODE_EMAIL_VERIFY_FAIL, { message: error }),
        },
      }}
      onCancel={() => {
        onCancel();
      }}
      confirmDisabled={!code}
      confirm={MvnMessage(COMMON_LANG.VERIFY)}
      cancel={MvnMessage(COMMON_LANG.CANCEL)}
    >
      <CrInputGroup spacing={7}>
        <CrInput
          label={MvnMessage(AUTH_USER_VERIFICATION_CODE_LANG.USER_VERIFICATION_CODE_LABEL)}
          type="text"
          placeholder={MvnMessage(AUTH_USER_VERIFICATION_CODE_LANG.USER_VERIFICATION_CODE_PLACEHOLDER)}
          autoComplete="off"
          onValueChange={(val) => setCode(val)}
        />
      </CrInputGroup>
    </CrDialog>
  );
}
