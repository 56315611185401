import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import { useRequest } from "@coralblack/flax";
import { AuthLayout } from "@layouts/Auth";
import { IsUserPasswordValid } from "maven-lib/dist/consts/validator";
import { LOCALE_LANG_KEY } from "maven-lib/dist/states";
import { classNames } from "maven-lib/dist/utils/misc";
import { DeviceVerificationCode } from "./DeviceVerificationCode";
import { SignUpEmailVerificationDialog } from "./partials/SignUpEmailVerificationDialog";
import { preparedAuthLink as preparedAuthLinkSSO, preparedCallbackLink, retrieveSSOPayload } from "./SSO";
import { CrCheckbox } from "@maven-surface/components/base/CrCheckbox";
import { CrRequestButton } from "@maven-surface/components/base/CrRequestButton";
import { useRoute } from "@maven-surface/hooks";
import { AUTH_USER_LANG, SSO_USER_VERIFICATION_CODE_LANG } from "@maven-msg/lang";
import { AUTH_USER_VERIFICATION_CODE_LANG } from "@maven-msg/lang/auth/userVerificationCode";
import { MvnMessage } from "@maven-msg/lib/Message";
import { LoLang, MavenService } from "@maven-rest/common/common.types";
import { AuthTokenFlax } from "@maven-rest/sso/AuthToken.flax";
import { CreateUserRequest } from "@maven-rest/sso/User.dto";
import { UserFlax } from "@maven-rest/sso/User.flax";
import { UserVerificationCodeFlax } from "@maven-rest/sso/UserVerificationCode.flax";
import { sessionSlice } from "@maven-rest/states";
import { getBrowserDefaultLanguage } from "@maven-rest/states/reducers/session";

const IS_NEED_NOTIFY_LAST_LOGINTIME = "isNeedNotifyLastLoginTime";

enum ConsentType {
  Agreement = "AGREEMENT",
  Personal = "PERSONAL",
  Marketing = "MARKETING",
}

export function SignUp() {
  const emailRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { query } = useRoute();
  const redirectTo = query.redirect || (state as any)?.from?.pathname;
  const service = MavenService.MavenClinicalCloud;

  const [emailVal, setEmailVal] = useState("");
  const [passwordVal, setPasswordVal] = useState("");
  const [passwordConfirmVal, setPasswordConfirmVal] = useState("");
  const [nameVal, setNameVal] = useState("");

  const [agreementAll, setAgreementAll] = useState(false);
  const [agreementLegal, setAgreementLegal] = useState(false);
  const [agreementPersonal, setAgreementPersonal] = useState(false);
  const [agreementMarketing, setAgreementMarketing] = useState(false);
  const [agreementAge, setAgreementAge] = useState(false);

  const [emailVerificationDialogVisibility, setEmailVerificationDialogVisibility] = useState(false);
  const [emailVerificationCodeUuid, setEmailVerificationCodeUuid] = useState("");
  const [deviceVerificationCodeUuid, setDeviceVerificationCodeUuid] = useState<string>("");

  const localStorageLanguage = localStorage.getItem(LOCALE_LANG_KEY) as LoLang;
  const lang = localStorageLanguage || getBrowserDefaultLanguage();

  const preparedAuthLink = (url: string) =>
    preparedAuthLinkSSO(
      `${url}${query.redirect ? `${url.includes("?") ? "&" : "?"}redirect=${encodeURIComponent(String(query.redirect))}` : ""}`
    );

  const { request: loginRequest, response: loginResponse } = useRequest(AuthTokenFlax.createAuthTokenForTest({} as any), {
    success: (resp) => {
      const defaultRedirect = process.env.REACT_APP_AUTH_FE_URL ?? redirectTo;

      sessionStorage.setItem(IS_NEED_NOTIFY_LAST_LOGINTIME, "true");
      dispatch(
        sessionSlice.actions.signIn({
          ...resp,
          redirectTo: retrieveSSOPayload()
            ? preparedCallbackLink({ token: resp.authToken.token, expiryAt: resp.authToken.expiryAt }) ?? ""
            : preparedAuthLink(defaultRedirect) ?? "",
        })
      );
    },
    error: (error) => {
      setDeviceVerificationCodeUuid("");
      return MvnMessage(AUTH_USER_LANG.USER_SIGNIN_FAIL, { message: error });
    },
  });

  const { request: sendVerificationCodeForDeviceRegistrationRequest, response: sendVerificationCodeForDeviceRegistrationResponse } =
    useRequest(
      {
        ...UserVerificationCodeFlax.createUserVerificationCodeForDeviceRegistration({ email: emailVal }),
      },
      {
        success: (resp) => {
          setDeviceVerificationCodeUuid(resp.userVerificationCode.uuid);

          return MvnMessage(SSO_USER_VERIFICATION_CODE_LANG.SSO_USER_VERIFICATION_CODE_USER_DEVICE_EMAIL_SENT);
        },
        error: (error) => {
          return { title: "Send Mail Error", message: error?.message || "An error has occurred." };
        },
      }
    );

  const { request: signUpRequest, response: signUpResponse } = useRequest(
    { ...UserFlax.createUser({} as any) },
    {
      success: () => {
        sendVerificationCodeForDeviceRegistrationRequest();

        return MvnMessage(AUTH_USER_LANG.USER_SIGNUP_SUCC);
      },
      error: (error) => MvnMessage(AUTH_USER_LANG.USER_SIGNUP_FAIL, { message: error }),
    }
  );

  const login = (email: string, password: string, service: MavenService, deviceToken: string) => {
    loginRequest({ data: { email, password, service, checkPreviousAuthToken: true, deviceToken } });
  };

  const disabled =
    !String(emailVal).trim() ||
    !isEmail(emailVal) ||
    !passwordVal ||
    passwordVal !== passwordConfirmVal ||
    !IsUserPasswordValid(passwordVal) ||
    !String(nameVal).trim() ||
    !agreementAge ||
    !agreementLegal ||
    !agreementPersonal;
  const busy = signUpResponse.busy || sendVerificationCodeForDeviceRegistrationResponse.busy;

  return (
    <AuthLayout>
      <SignUpEmailVerificationDialog
        visibility={emailVerificationDialogVisibility}
        email={emailVal}
        verificationCodeUuid={emailVerificationCodeUuid}
        onVerified={(code) => {
          setEmailVerificationDialogVisibility(false);

          const req: CreateUserRequest = {
            email: emailVal,
            password: passwordVal,
            name: nameVal,
            verificationCodeUuid: emailVerificationCodeUuid,
            verificationCode: code,
            agreeMarketing: agreementMarketing,
          };

          signUpRequest({ data: req as any });
        }}
        onCancel={() => setEmailVerificationDialogVisibility(false)}
      />
      <div className="header">
        <h2 className="title">Sign up</h2>
        <p className="help">
          Any problem?{" "}
          <a href="https://www.jnpmedi.com" target="_blank" rel="noopener noreferrer">
            Ask us anything!
          </a>
        </p>
      </div>
      {!deviceVerificationCodeUuid && (
        <div className="form">
          <fieldset className="type--label">
            <legend>Sign in</legend>
            <label htmlFor="email">
              Email *
              <input
                ref={emailRef}
                id="email"
                placeholder="Email"
                maxLength={256}
                className={classNames("email", !!emailVal && !isEmail(emailVal) && "state--error")}
                type="email"
                onChange={(e) => setEmailVal(e.target.value)}
              />
            </label>
            <label htmlFor="password">
              Password *
              <input
                id="password"
                placeholder="Password"
                maxLength={1024}
                className={classNames("password", !!passwordVal && !IsUserPasswordValid(passwordVal) && "state--error")}
                type="password"
                onChange={(e) => setPasswordVal(e.target.value)}
              />
              <em>{MvnMessage(AUTH_USER_LANG.USER_PASSWORD_RULE)}</em>
            </label>
            <label htmlFor="passwordConfirm">
              Password Confirm *
              <input
                id="passwordConfirm"
                placeholder="Password Confirm"
                className={classNames("password", !!passwordConfirmVal && passwordVal !== passwordConfirmVal && "state--error")}
                type="password"
                onChange={(e) => setPasswordConfirmVal(e.target.value)}
              />
            </label>
            <label htmlFor="name">
              Name *
              <input
                id="name"
                placeholder="Name"
                maxLength={32}
                className="name"
                type="text"
                onChange={(e) => {
                  setNameVal(e.target.value);
                }}
              />
            </label>
            <label htmlFor="legal-all" className="type--check legal--all">
              <CrCheckbox
                id="legal-all"
                checked={agreementAll}
                onValueChange={(e) => {
                  setAgreementAll(e);
                  setAgreementLegal(e);
                  setAgreementPersonal(e);
                  setAgreementMarketing(e);
                  setAgreementAge(e);
                }}
              />
              <p>{MvnMessage(AUTH_USER_LANG.USER_SIGNUP_AGREEMENT_AGREE_ALL)}</p>
            </label>
            <label htmlFor="legal-agreement" className="type--check legal--agreement">
              <CrCheckbox
                id="legal-agreement"
                checked={agreementLegal}
                onValueChange={(e) => {
                  setAgreementLegal(e);
                  setAgreementAll(e && agreementPersonal && agreementMarketing && agreementAge);
                }}
              />
              <p>
                {MvnMessage(AUTH_USER_LANG.USER_SIGNUP_AGREEMENT_LEGAL_PREFIX)}{" "}
                <a href={getConsentGuideUrl(ConsentType.Agreement, lang)} target="_blank" rel="noopener noreferrer">
                  {MvnMessage(AUTH_USER_LANG.USER_SIGNUP_AGREEMENT_LEGAL)}
                </a>
                {MvnMessage(AUTH_USER_LANG.USER_SIGNUP_AGREEMENT_LEGAL_SUFFIX)}{" "}
                <strong>{MvnMessage(AUTH_USER_LANG.USER_SIGNUP_AGREEMENT_REQUIRED)}</strong>
              </p>
            </label>
            <label htmlFor="legal-personal" className="type--check legal--agree-personal">
              <CrCheckbox
                id="legal-personal"
                checked={agreementPersonal}
                onValueChange={(e) => {
                  setAgreementPersonal(e);
                  setAgreementAll(agreementLegal && e && agreementMarketing && agreementAge);
                }}
              />
              <p>
                {MvnMessage(AUTH_USER_LANG.USER_SIGNUP_AGREEMENT_PERSONAL_PREFIX)}{" "}
                <a href={getConsentGuideUrl(ConsentType.Personal, lang)} target="_blank" rel="noopener noreferrer">
                  {MvnMessage(AUTH_USER_LANG.USER_SIGNUP_AGREEMENT_PERSONAL_DATA)}
                </a>
                {MvnMessage(AUTH_USER_LANG.USER_SIGNUP_AGREEMENT_PERSONAL_SUFFIX)}{" "}
                <strong>{MvnMessage(AUTH_USER_LANG.USER_SIGNUP_AGREEMENT_REQUIRED)}</strong>
              </p>
            </label>
            <label htmlFor="legal-marketing" className="type--check legal--agree-marketing">
              <CrCheckbox
                id="legal-marketing"
                checked={agreementMarketing}
                onValueChange={(e) => {
                  setAgreementMarketing(e);
                  setAgreementAll(agreementLegal && agreementPersonal && e && agreementAge);
                }}
              />
              <p>
                {MvnMessage(AUTH_USER_LANG.USER_SIGNUP_AGREEMENT_MARKETING_PREFIX)}{" "}
                <a href={getConsentGuideUrl(ConsentType.Marketing, lang)} target="_blank" rel="noopener noreferrer">
                  {MvnMessage(AUTH_USER_LANG.USER_SIGNUP_MARKETING_LEGAL)}
                </a>
                {MvnMessage(AUTH_USER_LANG.USER_SIGNUP_AGREEMENT_MARKETING_SUFFIX)}{" "}
                <strong>{MvnMessage(AUTH_USER_LANG.USER_SIGNUP_AGREEMENT_OPTIONAL)}</strong>
              </p>
            </label>
            <label htmlFor="legal-age" className="type--check legal--agree-age">
              <CrCheckbox
                id="legal-age"
                checked={agreementAge}
                onValueChange={(e) => {
                  setAgreementAge(e);
                  setAgreementAll(agreementLegal && agreementPersonal && agreementMarketing && e);
                }}
              />
              <p>
                {MvnMessage(AUTH_USER_LANG.USER_SIGNUP_AGREEMENT_AGE)}{" "}
                <strong>{MvnMessage(AUTH_USER_LANG.USER_SIGNUP_AGREEMENT_REQUIRED)}</strong>
              </p>
            </label>
          </fieldset>
          <div className="button">
            <CrRequestButton
              api={() => ({ ...UserVerificationCodeFlax.createUserVerificationCodeForSignUp({ email: emailVal }) })}
              delegate={{
                success: (resp) => {
                  setEmailVerificationCodeUuid(resp.userVerificationCode.uuid);
                  setEmailVerificationDialogVisibility(true);

                  return MvnMessage(SSO_USER_VERIFICATION_CODE_LANG.SSO_USER_VERIFICATION_CODE_SIGN_UP_EMAIL_SENT);
                },
                error: (error) => MvnMessage(AUTH_USER_VERIFICATION_CODE_LANG.USER_VERIFICATION_CODE_EMAIL_SEND_FAIL, { message: error }),
              }}
              disabled={disabled}
              loading={busy}
              color="primary"
            >
              Sign Up
            </CrRequestButton>
            <hr />
            <Link to={preparedAuthLink("/auth/sign-in")} className="cr-button primary">
              <em>Already registered?</em> Sign In.
            </Link>
          </div>
        </div>
      )}
      {deviceVerificationCodeUuid && (
        <DeviceVerificationCode
          email={emailVal}
          password={passwordVal}
          service={service}
          verificationCodeUuid={deviceVerificationCodeUuid}
          login={login}
          loginResponseBusy={loginResponse.busy}
        />
      )}
    </AuthLayout>
  );
}

function getConsentGuideUrl(consentType: ConsentType, lang: LoLang): string {
  const KO_AGREEMENT_URL = "https://mvn.do/legal/agreement";
  const EN_AGREEMENT_URL = "https://mvn.do/legal/agreement/en";

  const KO_PERSONAL_URL = "https://mvn.do/legal/personal";
  const EN_PERSONAL_URL = "https://mvn.do/legal/personal/en";

  const KO_MARKETING_URL = "https://mvn.do/legal/marketing";
  const EN_MARKETING_URL = "https://mvn.do/legal/marketing/en";

  function getAgreementUrl(lang: LoLang): string {
    if (lang === LoLang.KO) return KO_AGREEMENT_URL;
    return EN_AGREEMENT_URL;
  }
  function getPersonalUrl(lang: LoLang): string {
    if (lang === LoLang.KO) return KO_PERSONAL_URL;
    return EN_PERSONAL_URL;
  }
  function getMarketingUrl(lang: LoLang): string {
    if (lang === LoLang.KO) return KO_MARKETING_URL;
    return EN_MARKETING_URL;
  }

  switch (consentType) {
    case ConsentType.Agreement:
      return getAgreementUrl(lang);
    case ConsentType.Personal:
      return getPersonalUrl(lang);
    case ConsentType.Marketing:
      return getMarketingUrl(lang);
  }
}
