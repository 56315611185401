import { useStore } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { RootState } from "@maven-rest/states";

interface RedirectSignInProps {
  from?: string;
  exact?: boolean;
}

export function RedirectSignIn({ from, exact }: RedirectSignInProps) {
  return (
    <Redirect
      from={from ?? "*"}
      exact={!!exact}
      to={{
        pathname: "/auth/sign-in",
        search: window.location.search,
      }}
    />
  );
}

interface ProtectedRouteProps extends RouteProps {}

export function RedirectRoute(props: ProtectedRouteProps) {
  const session = useStore<RootState>().getState().session;
  const { exact = false, render } = props;
  const Component: any = props.component;

  const hasToken = checkToken(session);
  return (
    <Route
      exact={exact}
      {...props}
      render={(props) => {
        if (hasToken) {
          return <Redirect to={{ pathname: "/redirect", search: props.location.search }} />;
        }

        if (render) {
          return render({ ...props });
        }

        return <Component {...props} />;
      }}
    />
  );
}

type SessionType = {
  token: string | null;
  expiryAt: Date | null;
};

function checkToken(session?: SessionType): boolean {
  if (session?.token) {
    return true;
  }

  return false;
}
