import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const SSO_USER_VERIFICATION_CODE_CODE: {
  SSO_USER_VERIFICATION_CODE_NOT_FOUND: MvnCodeBE;
  SSO_USER_VERIFICATION_CODE_SIGNUP_EMAIL_DUPLICATED: MvnCodeBE;
  SSO_USER_VERIFICATION_CODE_PASSWORD_RESET_EMAIL_NOTFOUND: MvnCodeBE;
  SSO_USER_VERIFICATION_CODE_USED: MvnCodeBE;
  SSO_USER_VERIFICATION_CODE_BLOCKED: MvnCodeBE;
  SSO_USER_VERIFICATION_CODE_EXPIRED: MvnCodeBE;
  SSO_USER_VERIFICATION_CODE_INCORRECT: MvnCodeBE;
} = {
  SSO_USER_VERIFICATION_CODE_NOT_FOUND: {
    status: 409,
    ko: "인증번호가 등록되지 않았습니다.",
    en: "The verification code is not registered.",
  },
  SSO_USER_VERIFICATION_CODE_SIGNUP_EMAIL_DUPLICATED: {
    status: 409,
    ko: "입력하신 이메일주소({email})는 이미 가입 되어있습니다.",
    en: "The requested email address({email}) is registered already.",
  },
  SSO_USER_VERIFICATION_CODE_PASSWORD_RESET_EMAIL_NOTFOUND: {
    status: 400,
    ko: "입력하신 이메일주소({email})는 가입 되어있지 않습니다.",
    en: "The requested email address({email}) is not registered.",
  },
  SSO_USER_VERIFICATION_CODE_USED: {
    status: 409,
    ko: "이미 사용된 인증정보입니다. 인증코드를 다시 요청해 주세요.",
    en: "You have used the verification token already. Please request the verification code again.",
  },
  SSO_USER_VERIFICATION_CODE_BLOCKED: {
    status: 429,
    ko: "인증 최대 시도 횟수를 초과하였습니다. 인증코드를 다시 요청해 주세요.",
    en: "You have exceeded the number of attempts. Please request the verification code again.",
  },
  SSO_USER_VERIFICATION_CODE_EXPIRED: {
    status: 400,
    ko: "인증코드가 만료되었습니다. 인증코드를 다시 요청해 주세요.",
    en: "Verification code has been expired. Please request the verification code again.",
  },
  SSO_USER_VERIFICATION_CODE_INCORRECT: {
    status: 400,
    ko: "인증코드가 일치하지 않습니다.",
    en: "Verification code is incorrect.",
  },
};

MvnCodeInitiate(SSO_USER_VERIFICATION_CODE_CODE);
