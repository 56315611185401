import React, { useEffect } from "react";
import { Checkbox } from "@fluentui/react/lib/Checkbox";

export interface CrCheckboxProps {
  id?: string;
  label?: string;
  defaultChecked?: boolean;
  checked?: boolean;
  disabled?: boolean;
  onValueChange?: (val: boolean, isInitial?: boolean) => void;
  description?: string;
}

export function CrCheckbox(props: CrCheckboxProps) {
  const { id, label, disabled, description, checked, defaultChecked, onValueChange, ...extras } = props;

  useEffect(() => {
    (typeof defaultChecked === "boolean" || typeof defaultChecked === "number") && onValueChange && onValueChange(defaultChecked, true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="cr-checkbox" {...extras}>
      <Checkbox
        id={id}
        label={label}
        checked={checked}
        defaultChecked={defaultChecked}
        disabled={disabled}
        onChange={(_, val) => onValueChange && onValueChange(val || false, false)}
      />
      {description && <span className="cr-checkbox-desc">{description}</span>}
    </div>
  );
}
