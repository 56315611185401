import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const SAFETY_WORKSPACE_SETTING_LANG: {
  WORKSPACE_SETTING_PAGE_DESCRIPTION: MvnCodeFE;
  WORKSPACE_SETTING_SAVE_SUCC: MvnCodeFE;
  WORKSPACE_SETTING_SAVE_FAIL: MvnCodeFE;
  WORKSPACE_SETTING_DESCRIPTION_LABEL: MvnCodeFE;
  WORKSPACE_SETTING_DESCRIPTION_PLACEHOLDER: MvnCodeFE;
  WORKSPACE_SETTING_PROTOCOL_NO_INPUT_LABEL: MvnCodeFE;
  WORKSPACE_SETTING_PROTOCOL_NO_INPUT_PLACEHOLDER: MvnCodeFE;
  WORKSPACE_SETTING_PRODUCT_NAME_INPUT_LABEL: MvnCodeFE;
  WORKSPACE_SETTING_PRODUCT_NAME_INPUT_PLACEHOLDER: MvnCodeFE;
  WORKSPACE_SETTING_COUNTRY_CODE_LABEL: MvnCodeFE;
  WORKSPACE_SETTING_COUNTRY_CODE_PLACEHOLDER: MvnCodeFE;
  WORKSPACE_SETTING_WORKSPACE_LABEL: MvnCodeFE;
  WORKSPACE_SETTING_WORKSPACE_PLACEHOLDER: MvnCodeFE;
  WORKSPACE_SETTING_WORKSPACE_CODE_LABEL: MvnCodeFE;
  WORKSPACE_SETTING_WORKSPACE_CODE_PLACEHOLDER: MvnCodeFE;
  WORKSPACE_SETTING_WORKSPACE_CODE_DESCRIPTION: MvnCodeFE;
  WORKSPACE_SETTING_WORKSPACE_TIMEZONE_LABEL: MvnCodeFE;
  WORKSPACE_SETTING_WORKSPACE_TIMEZONE_PLACEHOLDER: MvnCodeFE;
  WORKSPACE_SETTING_CASE_AUTO_LOCK_LABEL: MvnCodeFE;
  WORKSPACE_SETTING_CASE_AUTO_LOCK_TOGGLE_LABEL: MvnCodeFE;
  WORKSPACE_SETTING_CASE_DELETE_LABEL: MvnCodeFE;
  WORKSPACE_SETTING_CASE_DELETE_TOGGLE_LABEL: MvnCodeFE;
  WORKSPACE_SETTING_IDENTIFIER_AUTO_GENERATE_LABEL: MvnCodeFE;
  WORKSPACE_SETTING_IDENTIFIER_GENERATE_FORMAT_LABEL: MvnCodeFE;
  WORKSPACE_SETTING_IDENTIFIER_GENERATE_FORMAT_PLACEHOLDER: MvnCodeFE;
  WORKSPACE_SETTING_IDENTIFIER_EDIT_TOGGLE_LABEL: MvnCodeFE;
  WORKSPACE_SETTING_WARNING_NOT_AUTHORIZED: MvnCodeFE;
} = {
  WORKSPACE_SETTING_PAGE_DESCRIPTION: {
    ko: "설정을 확인하고 관리할 수 있습니다.",
    en: "Check and manage workspace settings.",
  },
  WORKSPACE_SETTING_SAVE_SUCC: {
    ko: "성공적으로 설정을 변경하였습니다.",
    en: "Setting has been saved successfully.",
  },
  WORKSPACE_SETTING_SAVE_FAIL: {
    ko: "설정 변경에 실패하였습니다. ({message})",
    en: "Failed to change setting. ({message})",
  },
  WORKSPACE_SETTING_DESCRIPTION_LABEL: {
    ko: "Description",
    en: "Description",
  },
  WORKSPACE_SETTING_DESCRIPTION_PLACEHOLDER: {
    ko: "Description을 입력해주세요",
    en: "Enter description",
  },
  WORKSPACE_SETTING_PROTOCOL_NO_INPUT_LABEL: {
    ko: "Protocol No",
    en: "Protocol No",
  },
  WORKSPACE_SETTING_PROTOCOL_NO_INPUT_PLACEHOLDER: {
    ko: "Protocol no을 입력해주세요",
    en: "Enter protocol no",
  },
  WORKSPACE_SETTING_PRODUCT_NAME_INPUT_LABEL: {
    ko: "Product Name",
    en: "Product Name",
  },
  WORKSPACE_SETTING_PRODUCT_NAME_INPUT_PLACEHOLDER: {
    ko: "Product name을 입력해주세요",
    en: "ex) JANUMED",
  },
  WORKSPACE_SETTING_COUNTRY_CODE_LABEL: {
    ko: "Country Code",
    en: "Country Code",
  },
  WORKSPACE_SETTING_COUNTRY_CODE_PLACEHOLDER: {
    ko: "Country Code를 선택해주세요.",
    en: "Please select a country code.",
  },
  WORKSPACE_SETTING_WORKSPACE_LABEL: {
    ko: "Workspace Name",
    en: "Workspace Name",
  },
  WORKSPACE_SETTING_WORKSPACE_PLACEHOLDER: {
    ko: "Workspace Name을 입력해주세요.",
    en: "Please enter a workspace name.",
  },
  WORKSPACE_SETTING_WORKSPACE_CODE_LABEL: {
    ko: "Workspace Code",
    en: "Workspace Code",
  },
  WORKSPACE_SETTING_WORKSPACE_CODE_PLACEHOLDER: {
    ko: "Workspace Code를 입력해주세요.",
    en: "Please enter a workspace code.",
  },
  WORKSPACE_SETTING_WORKSPACE_CODE_DESCRIPTION: {
    ko: "영문 대문자 60자 이내",
    en: "Use only capital letters up to 60 characters",
  },
  WORKSPACE_SETTING_WORKSPACE_TIMEZONE_LABEL: {
    ko: "Workspace Timezone",
    en: "Workspace Timezone",
  },
  WORKSPACE_SETTING_WORKSPACE_TIMEZONE_PLACEHOLDER: {
    ko: "Workspace Timezone을 선택해주세요.",
    en: "Please select a workspace timezone.",
  },
  WORKSPACE_SETTING_CASE_AUTO_LOCK_LABEL: {
    ko: "Case Auto Lock",
    en: "Case Auto Lock",
  },
  WORKSPACE_SETTING_CASE_AUTO_LOCK_TOGGLE_LABEL: {
    ko: "Use Case Auto Lock",
    en: "Use Case Auto Lock",
  },
  WORKSPACE_SETTING_CASE_DELETE_LABEL: {
    ko: "Case Delete",
    en: "Case Delete",
  },
  WORKSPACE_SETTING_CASE_DELETE_TOGGLE_LABEL: {
    ko: "Allow to delete Case",
    en: "Allow to delete Case",
  },
  WORKSPACE_SETTING_IDENTIFIER_AUTO_GENERATE_LABEL: {
    ko: "Use Auto Generate",
    en: "Use Auto Generate",
  },
  WORKSPACE_SETTING_IDENTIFIER_GENERATE_FORMAT_LABEL: {
    ko: "Generate Format",
    en: "Generate Format",
  },
  WORKSPACE_SETTING_IDENTIFIER_EDIT_TOGGLE_LABEL: {
    ko: "Allow to edit Case Identifier",
    en: "Allow to edit Case Identifier",
  },
  WORKSPACE_SETTING_IDENTIFIER_GENERATE_FORMAT_PLACEHOLDER: {
    ko: "Generate Format을 선택해주세요.",
    en: "Please select a generate format.",
  },
  WORKSPACE_SETTING_WARNING_NOT_AUTHORIZED: {
    ko: "Workspace 설정을 위한 권한이 존재하지 않습니다.",
    en: "You need workspace settings edit privileges to edit workspace settings.",
  },
};

MvnCodeInitiate(SAFETY_WORKSPACE_SETTING_LANG);
