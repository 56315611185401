import { useEffect, useState } from "react";

const DEFAULT_DELAY_IN_MS = 200;

/**
 *
 * @param state 디바운싱할 state
 * @param delay - (optional) 밀리초 단위 지연 시간. 기본 값 200 ms
 * @returns {T} 디바운싱된 상태 값
 */
const useDebounce = <T>(state: T, delay?: number): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(state);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(state);
    }, delay ?? DEFAULT_DELAY_IN_MS);

    return () => clearTimeout(handler);
  }, [state, delay]);

  return debouncedValue;
};

export default useDebounce;
