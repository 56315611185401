import { useState } from "react";
import { useRequest } from "@coralblack/flax";
import { CrButton } from "@maven-surface/components/base/CrButton";
import { AUTH_USER_VERIFICATION_CODE_LANG, SSO_USER_VERIFICATION_CODE_LANG } from "@maven-msg/lang";
import { MvnMessage } from "@maven-msg/lib/Message";
import { MavenService } from "@maven-rest/common/common.types";
import { UserDeviceFlax } from "@maven-rest/sso/UserDevice.flax";
import { UserVerificationCodeFlax } from "@maven-rest/sso/UserVerificationCode.flax";
import { DEVICE_KEY, getCookie, setCookie } from "@maven-rest/states";

interface VerificationCodeProps {
  email: string;
  password: string;
  service: MavenService;
  verificationCodeUuid: string;
  login: (email: string, password: string, service: MavenService, token: string) => void;
  loginResponseBusy: boolean;
}

export function DeviceVerificationCode(props: VerificationCodeProps) {
  const { email, password, service, verificationCodeUuid, login, loginResponseBusy } = props;
  const [verificationCode, setVerificationCode] = useState<string>("");

  const { request: createMyDeviceRequest, response: createMyDeviceResponse } = useRequest(
    {
      ...UserDeviceFlax.createMyDevice({
        uuid: verificationCodeUuid,
        code: verificationCode,
        token: getCookie(DEVICE_KEY),
      }),
    },
    {
      success: (resp) => {
        const { expiryAt, token } = resp.deviceToken;

        if (token) {
          setCookie(DEVICE_KEY, token, new Date(expiryAt));
        }

        login(email, password, service, token);
      },
      error: (error) => MvnMessage(AUTH_USER_VERIFICATION_CODE_LANG.USER_VERIFICATION_CODE_EMAIL_VERIFY_FAIL, { message: error }),
    }
  );

  const { request: verifyUserVerificationCodeRequest, response: verifyUserVerificationCodeResponse } = useRequest(
    {
      ...UserVerificationCodeFlax.verifyUserVerificationCode({
        uuid: verificationCodeUuid,
        code: verificationCode,
      }),
    },
    {
      success: (resp) => {
        createMyDeviceRequest();
      },
      error: (error) => MvnMessage(AUTH_USER_VERIFICATION_CODE_LANG.USER_VERIFICATION_CODE_EMAIL_VERIFY_FAIL, { message: error }),
    }
  );

  return (
    <div className="form">
      <fieldset>
        <legend>Sign in</legend>
        <label htmlFor="code">
          <input
            value={verificationCode}
            id="code"
            placeholder={MvnMessage(SSO_USER_VERIFICATION_CODE_LANG.SSO_USER_VERIFICATION_CODE_PLACEHOLDER)}
            className="code"
            type="text"
            onChange={(e) => {
              setVerificationCode(e.target.value);
            }}
            onKeyDown={(e) => e.key === "Enter" && verificationCode && verifyUserVerificationCodeRequest()}
          />
        </label>
      </fieldset>
      <div className="button">
        <CrButton
          loading={loginResponseBusy || verifyUserVerificationCodeResponse.busy || createMyDeviceResponse.busy}
          color="primary"
          disabled={!verificationCode || !verificationCodeUuid}
          onClick={() => {
            verifyUserVerificationCodeRequest();
          }}
        >
          Verify
        </CrButton>
      </div>
    </div>
  );
}
