import { MvnCodeBE } from "../code/const";
import { MvnCodeFE } from "../lang/const";

function hasOwnProperty<X extends {}, Y extends PropertyKey>(obj: X, prop: Y): obj is X & Record<Y, unknown> {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

export const MAVEN_MSG = "MAVEN_MSG";
export type MvnCode = MvnCodeFE | MvnCodeBE;

export type MVN_STATUS_CODE = 303 | 400 | 401 | 403 | 404 | 409 | 413 | 429 | 500 | 503;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MvnMessageBindForApiResp = { result: boolean; code: string; message: string; data: any };
export type MvnMessageBinds = { [key: string]: string | number | bigint | boolean | MvnMessageBindForApiResp };
export type MvnCodes = { [key: string]: MvnCode };

export const MVN_MSGS: { [key: string]: { type: "MVN-CODE-BE" | "MVN-CODE-FE"; ko: string; en: string } } = {};

export function MvnCodeInitiate(codes: MvnCodes) {
  Object.keys(codes).forEach(code => {
    codes[code].code = codes[code].code || code;

    if (hasOwnProperty(codes[code], "status")) {
      codes[code].type = "MVN-CODE-BE";
    } else {
      codes[code].type = "MVN-CODE-FE";
    }

    if (
      MVN_MSGS[code] &&
      MVN_MSGS[code].type !== codes[code].type &&
      (MVN_MSGS[code].ko !== codes[code].ko || MVN_MSGS[code].en !== codes[code].en)
    ) {
      throw new Error(`MVN_MSG, Code duplicated, ${codes[code].type}.${code}`);
    }

    MVN_MSGS[code] = {
      type: codes[code].type,
      ko: codes[code].ko,
      en: codes[code].en,
    };
  });
}
