import { useDispatch, useStore } from "react-redux";
import { RouteProps } from "react-router-dom";
import { FxGuard } from "@coralblack/flax";
import { preparedCallbackLink } from "@pages/auth/SSO";
import { UserFlax } from "@maven-rest/sso/User.flax";
import { RootState, sessionSlice } from "@maven-rest/states";

interface ProtectedRouteProps extends RouteProps {}

export function RedirectPage(props: ProtectedRouteProps) {
  const session = useStore<RootState>().getState().session;

  const dispatch = useDispatch();

  return (
    <FxGuard
      key="static"
      api={{ ...UserFlax.getMyInfo({}) }}
      loading={() => <div className="app-spinner" />}
      render={(resp) => {
        if (resp) {
          document.location.href = preparedCallbackLink(session) || process.env.REACT_APP_AUTH_FE_URL!;
          return <></>;
        }

        return;
      }}
      error={(err) => {
        if (err?.code === "CREDENTIAL-INVALID") {
          dispatch(sessionSlice.actions.signOut());

          return <></>;
        }

        return (
          <>
            [{err?.code || "UNKNOWN"}] {err?.message || "An error has occurred."}
          </>
        );
      }}
    />
  );
}
