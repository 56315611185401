export class UserVerificationCodeCriteria {
  static VERIFY_CODE_FOR_PASSWORD_INIT_LENGTH = 8;
  static MAX_FAILURE_COUNT = 5;
}

export enum UserVerificationCodeStatus {
  None = "NONE",
  Wait = "WAIT",
  Sending = "SENDING",
  SendingFailed = "SENDING-FAILED",
  Sent = "SENT",
  Verified = "VERIFIED",
  Used = "USED",
  Blocked = "BLOCKED",
}

export enum UserVerificationCodeMethod {
  Email = "EMAIL",
  SMS = "SMS",
}

export enum UserVerificationCodeType {
  SignUp = "SIGN-UP",
  PasswordReset = "PASSWORD-RESET",
  PasswordInit = "PASSWORD-INIT",
  DeviceRegistration = "DEVICE-REGISTRATION",
  EmailVerification = "EMAIL-VERIFICATION",
  MFA = "MFA",
}

export class UserVerificationCode {
  id: bigint;
  uuid: string;
  userId: bigint;
  status: UserVerificationCodeStatus;
  method: UserVerificationCodeMethod;
  type: UserVerificationCodeType;
  failureCount: number;
  expiryAt: Date;
  createdAt: Date;
}

// CreateUserVerificationCode

export class CreateUserVerificationCodeForSignUpRequest {
  email: string;
  userAgent?: string;
}

export class CreateUserVerificationCodeForPasswordResetRequest {
  email: string;
}

// Verification Code For Password Init
export class CreateUserVerificationCodeForPasswordInitRequest {
  email: string;
}

export class CreateUserVerificationCodeForPasswordInitResponse {
  userVerificationCode: UserVerificationCode;
}

export class VerifyUserVerificationCodeForPasswordInitRequest {
  email: string;
  code: string;
}

export class VerifyUserVerificationCodeForPasswordInitResponse {
  data: boolean;
}

export class CreateUserVerificationCodeForDeviceRegistrationRequest {
  email: string;
}

export class CreateUserVerificationCodeForMFARequest {
  email: string;
  password: string;
}

export class CreateUserVerificationCodeResponse {
  userVerificationCode: UserVerificationCode;
}

// CreateEmailVerificationCode

export class CreateEmailVerificationCodeRequest {
  email: string;
}

// VerifyUserVerificationCode

export class VerifyUserVerificationCodeRequest {
  uuid: string;
  code: string;
}

export class VerifyUserVerificationCodeResponse {
  verified: boolean;
  recipient: string;
}
