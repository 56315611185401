import { Route, Switch } from "react-router-dom";
import { LegacySignIn, SignIn } from "@pages/auth/SignIn";
import { SSO } from "@pages/auth/SSO";
import { RedirectRoute, RedirectSignIn } from "../../RedirectRoute";

export const SignInRoutes = () => {
  return (
    <Switch>
      <Route path={"/auth/sign-in/sso"} exact render={() => <SSO />} />
      <RedirectRoute
        exact
        path={"/auth/sign-in"}
        render={() => (process.env.REACT_APP_FEATURE_SSO_API === "true" ? <SignIn /> : <LegacySignIn />)}
      />

      <RedirectSignIn />
    </Switch>
  );
};
