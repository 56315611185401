import { MavenService } from "../common/common.types";

export class AuthTokenCriteria {
  static MAX_FAILURE_COUNT = 5;
  static MAX_PASSWORD_CHANGE_CYCLE_DAY = 90;
  static FAIL_CHECK_TERM_MINUTE = 5;
  static ALIVE_CRITERIA_INTERVAL_MINUTE = 2;
  static SESSION_TIMEOUT_SECOND = 60 * 60;
  static SESSION_TIMEOUT_EXTEND_SECOND = 60 * 60;
}

export enum AuthTokenDestroyedReason {
  Expired = "EXPIRED",
  SignOut = "SIGN_OUT",
  NewToken = "NEW_TOKEN",
  EsignFail = "ESIGN_FAIL",
}

export class AuthToken<GrantPayloadT extends AuthTokenGrantPayload = any> {
  id: bigint;
  token: string;
  userId: bigint;
  expiryAt: Date;
  createdAt: Date;
  grant?: GrantPayloadT;
  service?: MavenService;
  isDestroyed?: boolean;
  destroyedReason?: AuthTokenDestroyedReason;
  ipAddress?: string;
}

export type AuthTokenGrantPayload = AuthTokenEcoaGrantPayload;

export interface AuthTokenCommonGrantPayload {
  password: string;
}

export type AuthTokenEcoaGrantPayload = {
  isolate: "ECOA";
  studyId: bigint;
  subjectId: bigint;
} & AuthTokenCommonGrantPayload;

// Create AuthToken
export class CreateAuthTokenRequest {
  email: string;
  checkPreviousAuthToken?: boolean;
  password: string;
  service: MavenService;
  remoteAddress?: string;
  deviceToken?: string;
  userAgent?: string;
}

export class CreateAuthTokenResponse {
  authToken: AuthToken;
}

// Refresh AuthToken
/** @todo SSO Production 배포 후 service 파라미터 필수값 추가 필요 */
export class RefreshAuthTokenRequest {}

export class RefreshAuthTokenResponse {
  authToken: AuthToken;
}

// Awake AuthToken
/** @todo SSO Production 배포 후 service 파라미터 필수값 추가 필요 */
export class AwakeAuthTokenRequest {}

export class AwakeAuthTokenResponse {
  authToken: AuthToken;
}

// Delete AuthToken
/** @todo SSO Production 배포 후 service 파라미터 필수값 추가 필요 */
export class DeleteAuthTokenRequest {}

export type DeleteAuthTokenResponse = boolean;

// For Test
export class CreateAuthTokenRequestForTest {
  email: string;
  checkPreviousAuthToken?: boolean;
  password: string;
  passwordEncrypted?: boolean;
  service: MavenService;
  remoteAddress?: string;
  deviceToken?: string;
}

export class CreateAuthTokenResponseForTest {
  authToken: AuthToken;
}

export class RefreshAuthTokenRequestForTest {
  service: MavenService;
}

export class RefreshAuthTokenResponseForTest {
  authToken: AuthToken;
}

export class AwakeAuthTokenRequestForTest {
  service: MavenService;
}

export class AwakeAuthTokenResponseForTest {
  authToken: AuthToken;
}

export class DeleteAuthTokenRequestForTest {
  service: MavenService;
}

export type DeleteAuthTokenResponseForTest = boolean;
