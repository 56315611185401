import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const SAFETY_WORKSPACE_LANG: {
  SAFETY_WORKSPACE_BREADCRUMB: MvnCodeFE;
  SAFETY_WORKSPACE_ADD_DIALOG_HEADER: MvnCodeFE;
  SAFETY_WORKSPACE_ADD_DIALOG_ORGANIZATION_LABEL: MvnCodeFE;
  SAFETY_WORKSPACE_ADD_DIALOG_INVENTORY_QUANTITY_REMAIN_LABEL: MvnCodeFE;
  SAFETY_WORKSPACE_ADD_DIALOG_NAME_LABEL: MvnCodeFE;
  SAFETY_WORKSPACE_ADD_DIALOG_NAME_PLACEHOLDER: MvnCodeFE;
  SAFETY_WORKSPACE_ADD_DIALOG_WORKSPACE_CODE_LABEL: MvnCodeFE;
  SAFETY_WORKSPACE_ADD_DIALOG_WORKSPACE_CODE_PLACEHOLDER: MvnCodeFE;
  SAFETY_WORKSPACE_ADD_DIALOG_DESCRIPTION_LABEL: MvnCodeFE;
  SAFETY_WORKSPACE_ADD_DIALOG_DESCRIPTION_PLACEHOLDER: MvnCodeFE;
  SAFETY_WORKSPACE_ADD_DIALOG_DICTIONARY_MEDDRA_LABEL: MvnCodeFE;
  SAFETY_WORKSPACE_ADD_DIALOG_WHODD_DRUG_DICTIONARY_LABEL: MvnCodeFE;
  SAFETY_WORKSPACE_ADD_DIALOG_MFDS_DICTIONARY_DRUG_LABEL: MvnCodeFE;
  SAFETY_WORKSPACE_ADD_DIALOG_MFDS_DICTIONARY_INGREDIENT_LABEL: MvnCodeFE;
  SAFETY_WORKSPACE_ADD_DIALOG_DICTIONARY_EDQM_STANDARD_TERM_LABEL: MvnCodeFE;
  SAFETY_WORKSPACE_ADD_DIALOG_COUNTRY_CODE_LABEL: MvnCodeFE;
  SAFETY_WORKSPACE_ADD_DIALOG_WORKSPACE_TIMEZONE_LABEL: MvnCodeFE;
  SAFETY_WORKSPACE_EXCEED_QUANTITY_HEADER: MvnCodeFE;
  SAFETY_WORKSPACE_UNSUBSCRIBED_PLAN_HEADER: MvnCodeFE;
  SAFETY_WORKSPACE_CANNOT_CREATE_WORKSPACE_HEADER: MvnCodeFE;
  SAFETY_WORKSPACE_QUANTITY_EXCEED_ALL_ORGS_CONTENT: MvnCodeFE;
  SAFETY_WORKSPACE_UNSUBSCRIBED_PLAN_ALL_ORGS_CONTENT: MvnCodeFE;
  SAFETY_WORKSPACE_INCLUDE_UNSUBSCRIBED_PLAN_ORG_CONTENT: MvnCodeFE;
  SAFETY_WORKSPACE_USABLE_QUANTITY_COUNT_LABEL: MvnCodeFE;
  SAFETY_WORKSPACE_USED_QUANTITY_COUNT_LABEL: MvnCodeFE;
  SAFETY_WORKSPACE_REMAIN_QUANTITY_COUNT_LABEL: MvnCodeFE;
  SAFETY_WORKSPACE_REDIRECT_SUBSCRIPTION_PAGE_CONFIRM_DESCRIPTION: MvnCodeFE;
  SAFETY_WORKSPACE_ADD_SUCC: MvnCodeFE;
  SAFETY_WORKSPACE_ADD_FAIL: MvnCodeFE;
  SAFETY_WORKSPACE_MODIFY_SUCC: MvnCodeFE;
  SAFETY_WORKSPACE_MODIFY_FAIL: MvnCodeFE;
  SAFETY_WORKSPACE_CRF_NOT_EXIST: MvnCodeFE;
  SAFETY_WORKSPACE_GRANT_PRIVILEGES_SUCC: MvnCodeFE;
  SAFETY_WORKSPACE_GRANT_PRIVILEGES_FAIL: MvnCodeFE;
  SAFETY_WORKSPACE_MODIFY_DIALOG_HEADER: MvnCodeFE;
  SAFETY_WORKSPACE_MODIFY_DIALOG_DESCRIPTION: MvnCodeFE;
  SAFETY_WORKSPACE_MODIFY_DIALOG_NAME_LABEL: MvnCodeFE;
  SAFETY_WORKSPACE_MODIFY_DIALOG_NAME_PLACEHOLDER: MvnCodeFE;
  SAFETY_WORKSPACE_MODIFY_DIALOG_WORKSPACE_CODE_LABEL: MvnCodeFE;
  SAFETY_WORKSPACE_MODIFY_DIALOG_WORKSPACE_CODE_PLACEHOLDER: MvnCodeFE;
  SAFETY_WORKSPACE_MODIFY_DIALOG_DESCRIPTION_LABEL: MvnCodeFE;
  SAFETY_WORKSPACE_MODIFY_DIALOG_DESCRIPTION_PLACEHOLDER: MvnCodeFE;
  SAFETY_WORKSPACE_DIALOG_WORKSPACE_CODE_TOOLTIP: MvnCodeFE;
  SAFETY_WORKSPACE_DIALOG_PROTOCOL_NO_INPUT_LABEL: MvnCodeFE;
  SAFETY_WORKSPACE_DIALOG_PROTOCOL_NO_INPUT_PLACEHOLDER: MvnCodeFE;
  SAFETY_WORKSPACE_DIALOG_PRODUCT_NAME_INPUT_LABEL: MvnCodeFE;
  SAFETY_WORKSPACE_DIALOG_PRODUCT_NAME_INPUT_PLACEHOLDER: MvnCodeFE;
  SAFETY_WORKSPACE_EMPTY_WORKSPACE: MvnCodeFE;
  SAFETY_WORKSPACE_EMPTY_WORKSPACE_BTN_CREATE: MvnCodeFE;
  SAFETY_WORKSPACE_SELECT_USE_DIALOG_HEADER: MvnCodeFE;
  SAFETY_WORKSPACE_SELECT_USE_DIALOG_DESCRIPTION: MvnCodeFE;
  SAFETY_WORKSPACE_SELECTED_USE_DIALOG_DESCRIPTION: MvnCodeFE;
  SAFETY_WORKSPACE_INVENTORY_QUANTITY_REMAIN_TOOLTIP: MvnCodeFE;
  SAFETY_WORKSPACE_PLAN_UPGRADE_DIALOG_HEADER: MvnCodeFE;
  SAFETY_WORKSPACE_PLAN_UPGRADE_DIALOG_DESCRIPTION: MvnCodeFE;
  SAFETY_WORKSPACE_SELECT_USE_SUCC: MvnCodeFE;
  SAFETY_WORKSPACE_SELECT_USE_FAIL: MvnCodeFE;
} = {
  SAFETY_WORKSPACE_BREADCRUMB: {
    ko: "Workspace를 확인하고 관리할 수 있습니다.",
    en: "Check and manage workspaces",
  },
  SAFETY_WORKSPACE_ADD_DIALOG_HEADER: {
    ko: "New Workspace",
    en: "New Workspace",
  },
  SAFETY_WORKSPACE_ADD_DIALOG_ORGANIZATION_LABEL: {
    ko: "Organization",
    en: "Organization",
  },
  SAFETY_WORKSPACE_ADD_DIALOG_INVENTORY_QUANTITY_REMAIN_LABEL: {
    ko: "Workspace Qty Remaining {remain}/{quantity}",
    en: "Workspace Qty Remaining {remain}/{quantity}",
  },
  SAFETY_WORKSPACE_ADD_DIALOG_NAME_LABEL: {
    ko: "Name",
    en: "Name",
  },
  SAFETY_WORKSPACE_ADD_DIALOG_NAME_PLACEHOLDER: {
    ko: "이름을 입력해주세요.",
    en: "Enter a workspace name",
  },
  SAFETY_WORKSPACE_ADD_DIALOG_WORKSPACE_CODE_LABEL: {
    ko: "Workspace Code",
    en: "Workspace Code",
  },
  SAFETY_WORKSPACE_ADD_DIALOG_WORKSPACE_CODE_PLACEHOLDER: {
    ko: "ex) JNPMEDI",
    en: "ex) JNPMEDI",
  },
  SAFETY_WORKSPACE_ADD_DIALOG_DESCRIPTION_LABEL: {
    ko: "Description",
    en: "Description",
  },
  SAFETY_WORKSPACE_ADD_DIALOG_DESCRIPTION_PLACEHOLDER: {
    ko: "Description을 입력해주세요",
    en: "Enter description",
  },
  SAFETY_WORKSPACE_ADD_DIALOG_DICTIONARY_MEDDRA_LABEL: {
    ko: "MedDRA",
    en: "MedDRA",
  },
  SAFETY_WORKSPACE_ADD_DIALOG_WHODD_DRUG_DICTIONARY_LABEL: {
    ko: "WHO Drug Dictionary",
    en: "WHO Drug Dictionary",
  },
  SAFETY_WORKSPACE_ADD_DIALOG_MFDS_DICTIONARY_DRUG_LABEL: {
    ko: "MFDS Drug Dictionary",
    en: "MFDS Drug Dictionary",
  },
  SAFETY_WORKSPACE_ADD_DIALOG_MFDS_DICTIONARY_INGREDIENT_LABEL: {
    ko: "MFDS Ingredient Dictionary",
    en: "MFDS Ingredient Dictionary",
  },
  SAFETY_WORKSPACE_ADD_DIALOG_DICTIONARY_EDQM_STANDARD_TERM_LABEL: {
    ko: "EDQM Standard Term",
    en: "EDQM Standard Term",
  },
  SAFETY_WORKSPACE_ADD_DIALOG_COUNTRY_CODE_LABEL: {
    ko: "Country Code",
    en: "Country Code",
  },
  SAFETY_WORKSPACE_ADD_DIALOG_WORKSPACE_TIMEZONE_LABEL: {
    ko: "Workspace Timezone",
    en: "Workspace Timezone",
  },
  SAFETY_WORKSPACE_EXCEED_QUANTITY_HEADER: {
    ko: "{organization}의 생성 가능한 Workspace 개수가 초과되었습니다.",
    en: "{organization}'s the number of workspaces that can be created has been exceeded.",
  },
  SAFETY_WORKSPACE_UNSUBSCRIBED_PLAN_HEADER: {
    ko: "{organization}의 현재 구독 중인 Maven Safety plan이 없습니다.",
    en: "{organization}'s Maven Safety plan is not currently subscribed.",
  },
  SAFETY_WORKSPACE_CANNOT_CREATE_WORKSPACE_HEADER: {
    ko: "Workspace 추가 생성이 불가능 합니다.",
    en: "It is not available to add a new workspace.",
  },
  SAFETY_WORKSPACE_QUANTITY_EXCEED_ALL_ORGS_CONTENT: {
    ko: "현재 소속된 모든 Organization의 생성 가능한 Workspace 개수가 초과되었습니다.",
    en: "The number of workspaces that can be created has been exceeded for your organizations.",
  },
  SAFETY_WORKSPACE_UNSUBSCRIBED_PLAN_ALL_ORGS_CONTENT: {
    ko: "현재 소속된 모든 Organization이 구독 중인 Maven Safety plan이 없습니다.",
    en: "Maven Safety plan is not currently subscribed for your organizations.",
  },
  SAFETY_WORKSPACE_INCLUDE_UNSUBSCRIBED_PLAN_ORG_CONTENT: {
    ko: "Maven Safety plan을 구독하지 않은 Organization에 소속되어 있습니다.",
    en: "Maven Safety plan is not currently subscribed for your organizations.",
  },
  SAFETY_WORKSPACE_USABLE_QUANTITY_COUNT_LABEL: {
    ko: "사용 가능한 최대 Workspace 개수 : ",
    en: "Maximum number of Workspaces available : ",
  },
  SAFETY_WORKSPACE_USED_QUANTITY_COUNT_LABEL: {
    ko: "현재 생성된 Workspace 개수 : ",
    en: "Number of Workspaces currently created : ",
  },
  SAFETY_WORKSPACE_REMAIN_QUANTITY_COUNT_LABEL: {
    ko: "사용 가능한 Workspace 개수 : ",
    en: "Number of remaining Workspaces available : ",
  },
  SAFETY_WORKSPACE_REDIRECT_SUBSCRIPTION_PAGE_CONFIRM_DESCRIPTION: {
    ko: "구독 확인 페이지로 이동 하시겠습니까?",
    en: "Are you sure you want to go to the subscription page?",
  },
  SAFETY_WORKSPACE_ADD_SUCC: {
    ko: "Workspace가 성공적으로 추가되었습니다.",
    en: "Workspace has been created successfully.",
  },
  SAFETY_WORKSPACE_ADD_FAIL: {
    ko: "Workspace 추가에 실패했습니다. ({message})",
    en: "Failed to create workspace. ({message})",
  },
  SAFETY_WORKSPACE_MODIFY_SUCC: {
    ko: "Workspace가 성공적으로 수정되었습니다.",
    en: "Workspace has been modified successfully.",
  },
  SAFETY_WORKSPACE_MODIFY_FAIL: {
    ko: "Workspace 수정에 실패했습니다. ({message})",
    en: "Failed to modify workspace. ({message})",
  },
  SAFETY_WORKSPACE_CRF_NOT_EXIST: {
    ko: "Workspace에서 이용 가능한 Report가 없습니다.",
    en: "There are no available reports in the workspace.",
  },
  SAFETY_WORKSPACE_GRANT_PRIVILEGES_SUCC: {
    ko: "Workspace 권한이 부여되었습니다.",
    en: "You have granted workspace privileges successfully.",
  },
  SAFETY_WORKSPACE_GRANT_PRIVILEGES_FAIL: {
    ko: "Workspace 권한 부여에 실패했습니다. ({error})",
    en: "Failed to granted workspace privileges. ({error})",
  },
  SAFETY_WORKSPACE_MODIFY_DIALOG_HEADER: {
    ko: "Edit Workspace",
    en: "Edit Workspace",
  },
  SAFETY_WORKSPACE_MODIFY_DIALOG_NAME_LABEL: {
    ko: "Name",
    en: "Name",
  },
  SAFETY_WORKSPACE_MODIFY_DIALOG_NAME_PLACEHOLDER: {
    ko: "이름을 입력해주세요.",
    en: "Enter a workspace name",
  },
  SAFETY_WORKSPACE_MODIFY_DIALOG_WORKSPACE_CODE_LABEL: {
    ko: "Workspace Code",
    en: "Workspace Code",
  },
  SAFETY_WORKSPACE_MODIFY_DIALOG_WORKSPACE_CODE_PLACEHOLDER: {
    ko: "ex) JNPMEDI",
    en: "ex) JNPMEDI",
  },
  SAFETY_WORKSPACE_MODIFY_DIALOG_DESCRIPTION_LABEL: {
    ko: "Description",
    en: "Description",
  },
  SAFETY_WORKSPACE_MODIFY_DIALOG_DESCRIPTION_PLACEHOLDER: {
    ko: "Description을 입력해주세요",
    en: "Enter description",
  },
  SAFETY_WORKSPACE_MODIFY_DIALOG_DESCRIPTION: {
    ko: "Workspace의 {privilege} 권한이 필요합니다.",
    en: "You need to have {privilege} privilege to edit.",
  },
  SAFETY_WORKSPACE_DIALOG_WORKSPACE_CODE_TOOLTIP: {
    ko: "대문자 + 숫자만 입력할 수 있습니다.",
    en: "You can only enter capital letters + numbers.",
  },
  SAFETY_WORKSPACE_DIALOG_PROTOCOL_NO_INPUT_LABEL: {
    ko: "Protocol No",
    en: "Protocol No",
  },
  SAFETY_WORKSPACE_DIALOG_PROTOCOL_NO_INPUT_PLACEHOLDER: {
    ko: "Protocol no을 입력해주세요",
    en: "Enter protocol no",
  },
  SAFETY_WORKSPACE_DIALOG_PRODUCT_NAME_INPUT_LABEL: {
    ko: "Product Name",
    en: "Product Name",
  },
  SAFETY_WORKSPACE_DIALOG_PRODUCT_NAME_INPUT_PLACEHOLDER: {
    ko: "Product name을 입력해주세요",
    en: "ex) JANUMED",
  },
  SAFETY_WORKSPACE_INVENTORY_QUANTITY_REMAIN_TOOLTIP: {
    ko: "Organization내 생성된 전체 Workspace 수를 포함하고 있어, 생성 가능한 Workspace 수량은 화면에 표시된 내역과 다를 수 있습니다.",
    en: "Workspace quantity remaining may differ from the numbers on the screen because it includes the total number of workspaces created in your organization.",
  },
  SAFETY_WORKSPACE_EMPTY_WORKSPACE: {
    ko: "생성된 Workspace가 없습니다.\n새로운 Workspace를 생성해주세요.",
    en: "No workspace has been created.\nCreate a new workspace.",
  },
  SAFETY_WORKSPACE_EMPTY_WORKSPACE_BTN_CREATE: {
    ko: "Workspace 생성하기",
    en: "Create an Workspace",
  },
  SAFETY_WORKSPACE_SELECT_USE_DIALOG_HEADER: {
    ko: "Select Workspace",
    en: "Select Workspace",
  },
  SAFETY_WORKSPACE_SELECT_USE_DIALOG_DESCRIPTION: {
    ko: "Maven Safety를 재구독해 주셔서 감사합니다.\n사용하실 Workspace를 선택해주세요.",
    en: "Welcome back to Maven Safety!\nPlease select a workspace(s) to use.",
  },
  SAFETY_WORKSPACE_SELECTED_USE_DIALOG_DESCRIPTION: {
    ko: "선택한 workspace를 계속해서 사용하시겠습니까? 선택한 수량만큼 생성가능한 workspace 수량에서 차감됩니다.\n\n선택하지 않은 workspace는 확인 버튼 클릭 후 즉시 삭제됩니다.",
    en: "Are you sure you want to use these workspaces? Once selected, the number of workspaces that can be created will be deducted.\n\nUnselected workspaces will be deleted immediately.",
  },
  SAFETY_WORKSPACE_PLAN_UPGRADE_DIALOG_HEADER: {
    ko: "Upgrade your plan",
    en: "Upgrade your plan",
  },
  SAFETY_WORKSPACE_PLAN_UPGRADE_DIALOG_DESCRIPTION: {
    ko: "추가로 Workspace를 사용하기 위해서 Plan을 업그레이드해 주세요.",
    en: "If you want to use more workspaces, please go to Plan page and upgrade your plan.",
  },
  SAFETY_WORKSPACE_SELECT_USE_SUCC: {
    ko: "사용할 workspace(s) 선택에 성공했습니다.",
    en: "Workspaces have been selected successfully.",
  },
  SAFETY_WORKSPACE_SELECT_USE_FAIL: {
    ko: "사용할 workspace(s) 선택에 실패했습니다. ({error})",
    en: "Failed to select workspaces. ({error})",
  },
};

MvnCodeInitiate(SAFETY_WORKSPACE_LANG);
