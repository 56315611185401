import { Member } from "./Member.dto";
import { Organization } from "./Organization.dto";
import { OrganizationDictionaryLicense } from "./OrganizationDictionaryLicense.dto";
import { Role } from "./Role.dto";
import { Paginatable } from "../common/Paginatable.dto";
import { RefResourceType } from "../ref/Ref.dto";
import { RefAudit, RefAuditAction, RefAuditCategory } from "../ref/RefAudit.dto";
import { User, UserEssential, UserInfo } from "../sso/User.dto";

export class PaginateAuthRefAuditsRequest {
  page: number;
  rpp: number;
  actantEmail?: string;
  userEmail?: string;
  action?: Array<RefAuditAction>;
  category?: RefAuditCategory.Auth_Role | RefAuditCategory.Auth_User;
  from?: Date;
  to?: Date;
}

export type PaginateAuthRefAuditsResponse = Paginatable<AuthRefAudit>;
export class PaginateOrganizationRefAuditsRequest {
  organizationId: bigint;
}

export type PaginateOrganizationRefAuditsResponse = Paginatable<OrganizationRefAudit>;

export class PaginateMyAuthRefAuditsRequest {}

export type PaginateMyAuthRefAuditsResponse = Paginatable<UserRefAudit>;

export enum AuthRefAuditTypeReserved {
  Entity = "ENTITY",
  Component = "COMPONENT",
  Data = "DATA",
}

export enum AuthRefAuditReason {
  DestroyedEntity = "DESTROYED_ENTITY",
}

export enum AuthUserRefAuditDataReserved {
  Name = "NAME",
  Email = "EMAIL",
  Phone = "PHONE",
  PasswordUpdatedAt = "PASSWORD_UPDATED_AT",
  AgreeMarketing = "AGREE_MARKETING",
}

export type AuthUserRefAuditDataPayload = Partial<
  Pick<User, "name" | "email" | "phone"> & Pick<UserInfo, "agreeMarketing"> & { passwordUpdatedAt: Date }
>;

export class UserRefAudit extends RefAudit {
  category: RefAuditCategory.Auth_User;
  user: User;
  actant: UserEssential;
}

export class RoleRefAudit extends RefAudit {
  category: RefAuditCategory.Auth_Role;
  role: Role;
}

export class RefAuditWithUserEssential extends RefAudit {
  user: UserEssential;
}

export class OrgMemberRefAudit extends RefAuditWithUserEssential {
  category: RefAuditCategory.Auth_Organization;
  type: RefResourceType.Auth_Member;
  member: Member;
}

export enum OrgMemberRefAuditComponentReserved {
  Status = "STATUS",
}

export type OrgMemberRefAuditComponentPayload = Pick<Member, "role" | "status"> & { userId: bigint; isDestroyed: boolean };

export enum OrgDictionaryLicenseRefAuditDataReserved {
  Manager = "MANAGER",
  DisabledReason = "DISABLED_REASON",
  ExpiryAt = "EXPIRY_AT",
  Status = "STATUS",
}

export type OrgDictionaryLicenseRefAuditDataPayload = Pick<
  OrganizationDictionaryLicense,
  "managerMemberId" | "disabledReason" | "expiryAt" | "isEnabled"
>;

export type OrganizationRefAuditDataPayload = Pick<Organization, "name">;

export enum OrganizationRefAuditDataReserved {
  Name = "NAME",
}

export type AuthRefAudit = RefAudit | RoleRefAudit | UserRefAudit;

export type OrganizationRefAudit = OrgMemberRefAudit;
