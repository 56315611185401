import styled from "@emotion/styled";
import { classNames } from "maven-lib/dist/utils/misc";
import { LoLang, MavenService } from "@maven-rest/common/common.types";
import { getBrowserDefaultLanguage, LOCALE_LANG_KEY } from "@maven-rest/states/reducers/session";

interface AuthLayoutProps {
  children: React.ReactNode;
}

export const AuthLayout = ({ children }: AuthLayoutProps) => {
  const service = MavenService.MavenClinicalCloud;

  const localStorageLanguage = localStorage.getItem(LOCALE_LANG_KEY) as LoLang;
  const lang = localStorageLanguage || getBrowserDefaultLanguage();

  return (
    <div className="app-layout">
      <div className="app-fullpage">
        <div className="app-auth">
          <div className="app-auth-wrapper">
            <div className="app-auth-side">
              <div className="app-auth-side-wrapper">
                <strong>{process.env.REACT_APP_NAME}</strong>
                <p>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </p>
              </div>
            </div>
            <StyledAuthContentContainer>
              <div className="app-auth-content-wrapper">
                <h1 className={classNames("logo", service.toLocaleLowerCase())}>{process.env.REACT_APP_NAME}</h1>

                {children}

                <strong className="copyright" style={{ marginTop: "40px" }}>
                  <StyledPrivacyPolicy>
                    <a href={getPrivacyPolicyUrl(lang)} target="_blank" rel="noopener noreferrer">
                      Privacy Policy
                    </a>
                  </StyledPrivacyPolicy>
                  Copyright &copy; 2020-{new Date().getFullYear()}{" "}
                  <a href="https://www.jnpmedi.com" target="_blank" rel="noopener noreferrer">
                    JNPMEDI
                  </a>{" "}
                  all rights reserved.
                </strong>
              </div>
            </StyledAuthContentContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export function getPrivacyPolicyUrl(lang: LoLang): string {
  const KO_PRIVACY_POLICY_URL = "https://mvn.do/legal/privacy";
  const EN_PRIVACY_POLICY_URL = "https://mvn.do/legal/privacy/en";

  if (lang === LoLang.KO) return KO_PRIVACY_POLICY_URL;
  return EN_PRIVACY_POLICY_URL;
}

const StyledPrivacyPolicy = styled.div`
  padding-bottom: 1rem;
  font-size: 0.82rem;
`;

const StyledAuthContentContainer = styled.div`
  position: relative;
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: calc(100% - 800px);
  min-height: 100%;

  background: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all ease-in-out 0.2s;

  @media (max-width: 1700px) {
    width: 55%;
  }

  @media (max-width: 1400px) {
    width: 60%;
  }

  @media (max-width: 600px) {
    width: 80%;
  }

  @media (max-width: 480px) {
    width: calc(100% - 10px);
  }
`;
