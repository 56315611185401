import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const SAFETY_CASE_COMMENT_LANG: {
  SAFETY_CASE_COMMENT_ADD_SUCC: MvnCodeFE;
  SAFETY_CASE_COMMENT_ADD_FAIL: MvnCodeFE;
  SAFETY_CASE_COMMENT_MODIFY_SUCC: MvnCodeFE;
  SAFETY_CASE_COMMENT_MODIFY_FAIL: MvnCodeFE;
  SAFETY_CASE_COMMENT_WRITE_LABEL: MvnCodeFE;
  SAFETY_CASE_COMMENT_WRITE_INPUT_PLACEHOLDER: MvnCodeFE;
} = {
  SAFETY_CASE_COMMENT_ADD_SUCC: {
    ko: "Comment가 성공적으로 추가되었습니다.",
    en: "Comment has been created successfully.",
  },
  SAFETY_CASE_COMMENT_ADD_FAIL: {
    ko: "Comment 추가에 실패했습니다. ({message})",
    en: "Failed to create a comment. ({message})",
  },
  SAFETY_CASE_COMMENT_MODIFY_SUCC: {
    ko: "Comment가 성공적으로 수정되었습니다.",
    en: "Comment has been modified successfully.",
  },
  SAFETY_CASE_COMMENT_MODIFY_FAIL: {
    ko: "Comment 수정에 실패했습니다. ({message})",
    en: "Failed to modify the comment. ({message})",
  },
  SAFETY_CASE_COMMENT_WRITE_LABEL: {
    ko: "Comment 작성",
    en: "Write Comment",
  },
  SAFETY_CASE_COMMENT_WRITE_INPUT_PLACEHOLDER: {
    ko: "Comment 내용을 입력해 주세요.",
    en: "Please enter comment.",
  },
};

MvnCodeInitiate(SAFETY_CASE_COMMENT_LANG);
