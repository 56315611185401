import { lazy } from "react";
import { Route, Switch } from "react-router-dom";
import { RedirectSignIn } from "../../RedirectRoute";

const PasswordFind = lazy(() => import("@pages/auth/password/Find"));
const PasswordChange = lazy(() => import("@pages/auth/password/Change"));
const PasswordLoading = lazy(() =>
  import("@pages/auth/PasswordLoading").then((module) => ({
    default: module.PasswordLoading,
  }))
);

export const PasswordRoutes = () => {
  return (
    <Switch>
      <Route path={"/auth/password/change"} component={PasswordChange} />
      <Route path={"/auth/password/change-done"} component={PasswordFind} />
      <Route path={"/auth/password/find"} component={PasswordFind} />
      <Route path={"/auth/password/loading"} component={PasswordLoading} />

      <RedirectSignIn />
    </Switch>
  );
};
