import { Route, Switch } from "react-router-dom";
import { SignUp } from "@pages/auth/SignUp";
import { RedirectSignIn } from "../../RedirectRoute";

export const SignUpRoutes = () => {
  return (
    <Switch>
      <Route path={"/auth/sign-up"} component={SignUp} />

      <RedirectSignIn />
    </Switch>
  );
};
