import { Suspense, useEffect } from "react";
import styled from "@emotion/styled";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { setDefaultTransformResponse } from "@coralblack/flax";
import { loadTheme } from "@fluentui/react";
import { RedirectPage } from "@pages/auth/Redirect";
import { ErrorLayout } from "@pages/system/layout/Error";
import { classNames } from "maven-lib/dist/utils/misc";
import { PasswordRoutes } from "./routes/auth/password";
import { SignInRoutes } from "./routes/auth/sign-in";
import { SignUpRoutes } from "./routes/auth/sign-up";
import { RedirectSignIn } from "./routes/RedirectRoute";
import { dialogIfTokenDestroyedByNewToken } from "@maven-surface/components/common/auth/TokenInvalidDialog";

import "@maven-surface/assets/style/App.scss";
import "@coralblack/flax/dist/styles/index.css";

loadTheme({
  defaultFontStyle: { fontFamily: '"Nunito", "Noto Sans KR", sans-serif', fontWeight: "400" },
  fonts: {
    xSmall: {
      fontSize: "0.69rem",
    },
    small: {
      fontSize: "0.75rem",
    },
    medium: {
      fontSize: "0.82rem",
    },
    large: {
      fontSize: "0.91rem",
    },
    xLarge: {
      fontSize: "1rem",
    },
  },
});

function App() {
  const appBadge = process.env.REACT_APP_BADGE;
  const appBadgeDisabled = process.env.REACT_APP_BADGE_DISABLE;

  useEffect(() => {
    document.body.classList.add("ready");

    setTimeout(() => {
      document.getElementById("spinner")?.remove();
    }, 1500);
  });

  setDefaultTransformResponse([
    function (data) {
      const o = JSON.parse(data);

      dialogIfTokenDestroyedByNewToken(o);

      return o;
    },
  ]);

  const demoMessage = getQueryParameterDemoMsg(window.location.search, "demo-msg");

  return (
    <BrowserRouter>
      <div
        className={classNames(
          "app-wrapper",
          !!appBadgeDisabled && `badge--disabled badge--${appBadgeDisabled}`,
          `badge--${String(appBadge || "unknown").toLowerCase()}`,
          !!demoMessage.length && "--demo"
        )}
      >
        {!!demoMessage.length && (
          <DemoMessageContainer>
            <p>{demoMessage}</p>
          </DemoMessageContainer>
        )}

        {!appBadgeDisabled && appBadge && (
          <div className={classNames("notranslate app-sidebar-ribbon", `--${String(appBadge).toLowerCase()}`)}>{appBadge}</div>
        )}
        <Suspense
          fallback={
            <div className="suspense-loading">
              <div className="loading">
                <div className="loader" />
              </div>
            </div>
          }
        >
          <Switch>
            <Redirect exact from="/auth" to="/auth/sign-in" />

            <Route path={"/auth/sign-in"}>
              <SignInRoutes />
            </Route>

            <Route path={"/auth/password"}>
              <PasswordRoutes />
            </Route>

            <Route path={"/auth/sign-up"}>
              <SignUpRoutes />
            </Route>

            <Route path="/redirect" exact component={RedirectPage} />
            <Route path="/404" exact component={ErrorLayout} />
            <RedirectSignIn from="/" exact />
            <RedirectSignIn />
            <Redirect from="*" to="/404?ep" />
          </Switch>
        </Suspense>
      </div>
    </BrowserRouter>
  );
}

export default App;

const DemoMessageContainer = styled.div`
  z-index: 40;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 55px;

  background: #fef49c;
  box-shadow: 0 0 3px #000;
  font-weight: bold;
  font-size: 2.5em;
  line-height: 55px;
  text-align: center;
  overflow: hidden;

  > p {
    margin: 0px;
  }
`;

function getQueryParameterDemoMsg(searchParameter: string, keyword: string): string {
  const ALLOWED_DISPLAY_ENVIRONMENT_STAGES = ["local", "develop", "demo"];
  const isDemoMessageAllowedEnvironment = ALLOWED_DISPLAY_ENVIRONMENT_STAGES.includes(String(process.env.REACT_APP_STAGE));

  if (!isDemoMessageAllowedEnvironment) {
    return "";
  }

  const params = new URLSearchParams(searchParameter);

  for (const [key, value] of params.entries()) {
    if (new RegExp(`^${keyword}$`).test(key)) {
      if (value.length < 30) {
        return value;
      }

      return "";
    }
  }

  return "";
}
