import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const SAFETY_WORKSPACE_MEMBERS_LANG: {
  SAFETY_WORKSPACE_MEMBERS_INVITE_SUCC: MvnCodeFE;
  SAFETY_WORKSPACE_MEMBERS_INVITE_FAIL: MvnCodeFE;
  SAFETY_WORKSPACE_MEMBERS_INVITE_DIALOG_HEADER: MvnCodeFE;
  SAFETY_WORKSPACE_MEMBERS_INVITE_DIALOG_TITLE_DEFAULT: MvnCodeFE;
  SAFETY_WORKSPACE_MEMBERS_INVITE_DIALOG_TITLE_DICTIONARY_LICENSE: MvnCodeFE;
  SAFETY_WORKSPACE_MEMBERS_INVITE_DIALOG_EMAIL_LABEL: MvnCodeFE;
  SAFETY_WORKSPACE_MEMBERS_INVITE_DIALOG_ROLE_LABEL: MvnCodeFE;
  SAFETY_WORKSPACE_MEMBERS_DELETE_SUCC: MvnCodeFE;
  SAFETY_WORKSPACE_MEMBERS_DELETE_FAIL: MvnCodeFE;
  SAFETY_WORKSPACE_MEMBERS_DELETE_DIALOG_HEADER: MvnCodeFE;
  SAFETY_WORKSPACE_MEMBERS_DELETE_DIALOG_TITLE: MvnCodeFE;
  SAFETY_WORKSPACE_MEMBERS_DELETE_DIALOG_EMAIL_PLACEHOLDER: MvnCodeFE;
  SAFETY_WORKSPACE_MEMBERS_LIST_TITLE_DESCRIPTION: MvnCodeFE;
  SAFETY_WORKSPACE_MEMBERS_INVITATION_DESCRIPTION: MvnCodeFE;
  SAFETY_WORKSPACE_MEMBERS_INVITATION_ACCEPT_CONFIRM: MvnCodeFE;
  SAFETY_WORKSPACE_MEMBERS_INVITATION_ACCEPT_SUCC: MvnCodeFE;
  SAFETY_WORKSPACE_MEMBERS_INVITATION_ACCEPT_FAIL: MvnCodeFE;
  SAFETY_WORKSPACE_MEMBERS_INVITATION_REJECT_CONFIRM: MvnCodeFE;
  SAFETY_WORKSPACE_MEMBERS_INVITATION_REJECT_SUCC: MvnCodeFE;
  SAFETY_WORKSPACE_MEMBERS_INVITATION_REJECT_FAIL: MvnCodeFE;
  SAFETY_WORKSPACE_MEMBERS_TRANSFER_OWNERSHIP_SUCC: MvnCodeFE;
  SAFETY_WORKSPACE_MEMBERS_TRANSFER_OWNERSHIP_FAIL: MvnCodeFE;
  SAFETY_WORKSPACE_MEMBERS_TRANSFER_OWNERSHIP_DIALOG_HEADER: MvnCodeFE;
  SAFETY_WORKSPACE_MEMBERS_TRANSFER_OWNERSHIP_DIALOG_TITLE: MvnCodeFE;
  SAFETY_WORKSPACE_MEMBERS_TRANSFER_OWNERSHIP_DIALOG_NEW_OWNER_LABEL: MvnCodeFE;
  SAFETY_WORKSPACE_MEMBERS_TRANSFER_OWNERSHIP_DIALOG_CHANGE_MY_ROLE_LABEL: MvnCodeFE;
  SAFETY_WORKSPACE_MEMBERS_TRANSFER_OWNERSHIP_DIALOG_CONFIRM: MvnCodeFE;
} = {
  SAFETY_WORKSPACE_MEMBERS_INVITE_SUCC: {
    ko: "멤버 초대 요청을 성공했습니다.",
    en: "Member invitation has been completed successfully.",
  },
  SAFETY_WORKSPACE_MEMBERS_INVITE_FAIL: {
    ko: "멤버 초대 요청이 실패했습니다. ({message})",
    en: "Failed to invite member. ({message})",
  },
  SAFETY_WORKSPACE_MEMBERS_INVITE_DIALOG_HEADER: {
    ko: "Invite Member",
    en: "Invite Member",
  },
  SAFETY_WORKSPACE_MEMBERS_INVITE_DIALOG_TITLE_DEFAULT: {
    ko: "초대할 사용자의 이메일 주소를 입력하세요.",
    en: "Enter email address of user that you want to invite.",
  },
  SAFETY_WORKSPACE_MEMBERS_INVITE_DIALOG_TITLE_DICTIONARY_LICENSE: {
    ko: "해당 유저가 dictionary license를 가지고 있는지 확인하세요.",
    en: "Please confirm that this person has dictionary license.",
  },
  SAFETY_WORKSPACE_MEMBERS_INVITE_DIALOG_EMAIL_LABEL: {
    ko: "Email",
    en: "Email",
  },
  SAFETY_WORKSPACE_MEMBERS_INVITE_DIALOG_ROLE_LABEL: {
    ko: "Role",
    en: "Role",
  },
  SAFETY_WORKSPACE_MEMBERS_DELETE_SUCC: {
    ko: "멤버 추방 요청을 성공했습니다.",
    en: "You have deleted member successfully.",
  },
  SAFETY_WORKSPACE_MEMBERS_DELETE_FAIL: {
    ko: "멤버 추방 요청이 실패했습니다. ({message})",
    en: "Failed to delete member. ({message})",
  },
  SAFETY_WORKSPACE_MEMBERS_DELETE_DIALOG_HEADER: {
    ko: "Delete Member",
    en: "Delete Member",
  },
  SAFETY_WORKSPACE_MEMBERS_DELETE_DIALOG_TITLE: {
    ko: "정말로 멤버 삭제를 진행하시겠습니까?\n삭제된 멤버는 더 이상 본 workspace를 사용할 수 없습니다.",
    en: "Are you sure you want to delete this member?\nIf you confirm, following member will be deleted from this workspace.",
  },
  SAFETY_WORKSPACE_MEMBERS_DELETE_DIALOG_EMAIL_PLACEHOLDER: {
    ko: "확인을 위해 추방할 멤버 이메일을 작성하세요.",
    en: "Write email to delete to confirm.",
  },
  SAFETY_WORKSPACE_MEMBERS_LIST_TITLE_DESCRIPTION: {
    ko: "Member를 초대하고 권한을 관리할 수 있습니다.",
    en: "Manage member and privileges",
  },
  SAFETY_WORKSPACE_MEMBERS_INVITATION_DESCRIPTION: {
    ko: "{inviterUserName}({inviterUserEmail}) 계정으로 부터 {workspace}에 새로운 초대를 받았습니다.",
    en: "You have a new invitation from {inviterUserName}({inviterUserEmail}) to join {workspace}.",
  },
  SAFETY_WORKSPACE_MEMBERS_INVITATION_ACCEPT_CONFIRM: {
    ko: "Member 초대를 수락하시겠습니까?",
    en: "Are you sure you want to accept the invitation to a member?",
  },
  SAFETY_WORKSPACE_MEMBERS_INVITATION_ACCEPT_SUCC: {
    ko: "Member 초대 수락이 완료되었습니다.",
    en: "Member invitation has been accepted successfully.",
  },
  SAFETY_WORKSPACE_MEMBERS_INVITATION_ACCEPT_FAIL: {
    ko: "Member 초대 수락에 실패하였습니다. ({message})",
    en: "Failed to accept member invite request. ({message})",
  },
  SAFETY_WORKSPACE_MEMBERS_INVITATION_REJECT_CONFIRM: {
    ko: "Member 초대를 거절하시겠습니까?",
    en: "Are you sure you want to reject the invitation?",
  },
  SAFETY_WORKSPACE_MEMBERS_INVITATION_REJECT_SUCC: {
    ko: "Member 초대 거절이 완료되었습니다.",
    en: "Member invitation has been rejected successfully.",
  },
  SAFETY_WORKSPACE_MEMBERS_INVITATION_REJECT_FAIL: {
    ko: "Member 초대 거절에 실패하였습니다. ({message})",
    en: "Failed to reject member invite request. ({message})",
  },
  SAFETY_WORKSPACE_MEMBERS_TRANSFER_OWNERSHIP_SUCC: {
    ko: "Transfer ownership 요청을 성공했습니다.",
    en: "Ownership has been transferred successfully.",
  },
  SAFETY_WORKSPACE_MEMBERS_TRANSFER_OWNERSHIP_FAIL: {
    ko: "Transfer ownership 요청이 실패했습니다. ({message})",
    en: "Failed to transfer ownership. ({message})",
  },
  SAFETY_WORKSPACE_MEMBERS_TRANSFER_OWNERSHIP_DIALOG_HEADER: {
    ko: "Transfer Ownership",
    en: "Transfer Ownership",
  },
  SAFETY_WORKSPACE_MEMBERS_TRANSFER_OWNERSHIP_DIALOG_TITLE: {
    ko: "Owner로 변경할 member 한 명을 선택하세요.",
    en: "Select one of member from organization to change owner.",
  },
  SAFETY_WORKSPACE_MEMBERS_TRANSFER_OWNERSHIP_DIALOG_NEW_OWNER_LABEL: {
    ko: "New Owner",
    en: "New Owner",
  },
  SAFETY_WORKSPACE_MEMBERS_TRANSFER_OWNERSHIP_DIALOG_CHANGE_MY_ROLE_LABEL: {
    ko: "Change My Role to",
    en: "Change My Role to",
  },
  SAFETY_WORKSPACE_MEMBERS_TRANSFER_OWNERSHIP_DIALOG_CONFIRM: {
    ko: "Change Owner",
    en: "Change Onwer",
  },
};

MvnCodeInitiate(SAFETY_WORKSPACE_MEMBERS_LANG);
